import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/authUtils';
import LoginToAccess from '../components/loginToAccess';
import NotFound from '../components/notFound';

import '../../portal-app/styles/index.scss';
//import RestrictedRoute from '../../portal-app/components/RestrictedRoute';
import useUserToken from '../../portal-app/hooks/useUserToken';
import Loading from '../../portal-app/utils/Loader';
import TopBar from '../../portal-app/components/Menu';
import HomeContainer from '../../portal-app/components/HomeContainer';

const Root = () => {
  const { isLoggedIn, isLoginInProgress } = useUserToken();

  return (
    <div>
      <TopBar />
      <Suspense fallback={<Loading />}>
        <div className="main-content-container">
          <div>
            {isLoginInProgress &&
              <Loading className="text-center mt-5" />
            }
            {!isLoggedIn &&
              <Switch>
                <Route exact strict path='/' component={LoginToAccess} />
                <Route path={loginCallbackPath} exact component={Callback} />
                <Route path={logoutCallbackPath} exact component={LogoutCallback} />
                <Route component={LoginToAccess} />
              </Switch>
            }
            {isLoggedIn &&
              <Switch>
                {/* Products */}
                {/* <RestrictedRoute any={[ isAdmin, isSuperadmin ]} exact strict path={`${URL_PORTAL_PRODUCTS_LIST}`} component={ProductsList} /> */}

                {/* Others */}
                <Route path={loginCallbackPath} exact render={() => <Redirect to='/'/>}/>
                <Route path={logoutCallbackPath} exact render={() => <Redirect to='/'/>}/>
                <Route path="/" exact component={HomeContainer} />
                <Route component={NotFound} />
              </Switch>
            }
          </div>
        </div>
      </Suspense>
    </div>
  );
};
export default Root;
